import React from "react";

export default function Vision() {
  return (
    <div className="feature section-padding my-5">
      <div className="container">
        <div className="row my-4">
          <div
            className="col-lg-6 d-flex align-items-center border-divider-right"
            data-aos="fade-up-right"
          >
            <div className="img-mons">
              <div className="row">
                <div className="col-12 ">
                  <h6 className="sub-title">Vision</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 className="home-content-main-title">
                    Bridging the Gap Between Technology and Your Career Success
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="home-about-desc">
                    Skill Zone Academy aspires to lay the technical foundation
                    at the earliest phase of the education to start their Job
                    career smoothly without too much of struggles as soon as
                    their college degree is completed. There is a great talent
                    pool who are hardly even finding an interview opportunity as
                    freshers in IT Profession just because of not having the
                    right skill sets which are needed in the Market and it leads
                    to switching their career which doesn’t suit their
                    education.
                  </p>
                  <p className="home-about-desc">
                    Our Vision is to help the students to find the right job at
                    the right time which fits their education and in their area
                    of Interest too.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center border-divider-left"
            data-aos="fade-up-left"
          >
            <div className="img-mons">
              <div className="row">
                <div className="col-12 ">
                  <h6 className="sub-title">Mission</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 className="home-content-main-title">
                    Empowering You with Practical Skills for a Successful Future
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="home-about-desc">
                    Skill Zone Academy is mainly aiming to create awareness to
                    college students and also working Professionals those who
                    would like to start their career in the IT Profession, on
                    what are all the Latest IT Technologies being used in the
                    industry and how can they groom themselves in their
                    respective area of interest to grab the Opportunities as
                    Freshers. We are offering courses in different areas like
                    Programming Languages, Web Designing, Data Analytics, Cloud
                    Technologies, Software Testing and in many other areas.
                  </p>
                  <p className="home-about-desc">
                    At the end of the Course Completion, we make sure that they
                    are equipped enough by involving them in implementation in
                    one of the real time project related use-cases in the areas
                    that they learnt. We extend to provide our unconditional
                    support in Resume Preparation, Arranging the Mock Interviews
                    and also in Job Assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
