import React, { useEffect, useState } from "react";
import "../style.css";
import MyVerticallyCenteredModal from "./Modal";
import Button from "react-bootstrap/Button";
import { userDetailContext } from "../App";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Navigate } from "react-router-dom";

function NavHeader() {
  const {
    handleShow,
    show,
    handleClose,
    setShow,
    firstTimeSkip,
    setFromCourses,
  } = React.useContext(userDetailContext);

  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  // useEffect(() => {
  //   const header = document.querySelector(".header-section");
  //   setHeaderTop(header.offsetTop);
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const enquiryHandler = () => {
    setFromCourses("");
    handleShow(true);
  };
  useEffect(() => {
    const loginFlag = localStorage.getItem("loggedIn");
    const modalSkip = sessionStorage.getItem("modalSkip");

    !loginFlag &&
      !modalSkip &&
      setTimeout(() => {
        setShow(true);
      }, 4000);
  }, []);
  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top" id="main-nav-bar">
        <Container>
          <Navbar.Brand href="/">
            <img src={require("../assets/logo-cropped-trans.png")} alt="..." />
            &nbsp;Skill Zone Academy
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <li className="nav-item ">
                <Link to="/" className="nav-link active">
                  <span>Home</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/courses" className="nav-link">
                  <span>Courses</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/business" className="nav-link">
                  <span>Business</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/contact-us" className="nav-link">
                  <span>Contact Us</span>
                </Link>
              </li>
            </Nav>
          </Navbar.Collapse>
          <Button
            variant="primary"
            onClick={enquiryHandler}
            id="enquiry-button"
            className="enquiry-btn btn-10"
          >
            Enquiry
          </Button>
          &emsp;
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>
      <MyVerticallyCenteredModal show={show} handleClose={handleClose} />
    </>
  );
  // return (
  //   <>
  //     <div
  //       className={`header-section header-transparent sticky-header section ${
  //         scroll > headerTop ? "is-sticky" : ""
  //       }`}
  //     >
  //       <nav
  //         className="navbar navbar-expand-lg bg-body-tertiary p-1"
  //         id="header-top-nav-bar"
  //       >
  //         <div className="container">
  //           <a className="navbar-brand" href="#">
  //             <img
  //               src={require("../assets/logo-cropped-trans.png")}
  //               alt="..."
  //             />
  //             &nbsp;
  //             <h5 className="m-auto logo-style">Skill Zone Academy</h5>
  //           </a>
  //           <button
  //             className="navbar-toggler"
  //             type="button"
  //             data-bs-toggle="collapse"
  //             data-bs-target="#navbarNavDropdown"
  //             aria-controls="navbarNavDropdown"
  //             aria-expanded="false"
  //             aria-label="Toggle navigation"
  //           >
  //             <span className="navbar-toggler-icon"></span>
  //           </button>
  //           <div
  //             className="collapse navbar-collapse justify-content-center"
  //             id="navbarNavDropdown"
  //           >
  //             <ul className="navbar-nav">
  //               <li className="nav-item ">
  //                 <Link to="/" className="nav-link active">
  //                   Home
  //                 </Link>
  //               </li>
  //               <li className="nav-item">
  //                 <Link to="/courses" className="nav-link">
  //                   Courses
  //                 </Link>
  //               </li>
  //               <li className="nav-item">
  //                 <Link to="/business" className="nav-link">
  //                   Business
  //                 </Link>
  //               </li>

  //               <li className="nav-item">
  //                 <Link to="/contact-us" className="nav-link">
  //                   Contact Us
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Button
  //                   variant="primary"
  //                   onClick={enquiryHandler}
  //                   id="enquiry-button"
  //                   className="enquiry-btn btn-10"
  //                 >
  //                   Enquiry
  //                 </Button>
  //               </li>
  //             </ul>
  //           </div>
  //           {/* <div>
  //             <Button
  //               variant="primary"
  //               onClick={enquiryHandler}
  //               id="enquiry-button"
  //               className="enquiry-btn btn-10"
  //             >
  //               Enquiry
  //             </Button>
  //           </div> */}
  //         </div>
  //       </nav>
  //       <MyVerticallyCenteredModal show={show} handleClose={handleClose} />
  //     </div>
  //   </>
  // );
}

export default NavHeader;
