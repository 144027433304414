import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div id="footer" className="mb-3 mb-sm-0 mb-lg-0 mb-xl-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <a className="navbar-brand" href="#">
              <img
                width={100}
                height={100}
                src={require("../assets/logo-trans-white.png")}
                alt="..."
              />
            </a>
            <h5 className="footer-header">Skill Zone Academy</h5>
          </div>
          <div className="col-12">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item ">
                      <Link to="/" className="nav-link active">
                        <span>Home</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                <Link to="/courses" className="nav-link">
                  <span>Courses</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/business" className="nav-link">
                  <span>Business</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/contact-us" className="nav-link">
                  <span>Contact Us</span>
                </Link>
              </li>
                     {/* <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="#"
                      >
                        Home
                      </a>
                    </li> 
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Courses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Business
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Contact Us
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/* <div className="row mt-3">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="#">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Courses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Business
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col">
            <div className="bottom-bar-socials">
              {/* <div className="inner">
                <span className="icons">
                  <a href="/">
                    <i className="fa-brands fa-square-facebook fa-2xs color-white"></i>
                  </a>
                  &emsp;
                  <a href="/">
                    <i className="fa fa-twitter"></i>
                  </a>
                  &emsp;
                  <a href="/">
                    <i className="fa fa-pinterest-p"></i>
                  </a>
                  &emsp;
                  <a href="/">
                    <i className="fa fa-rss"></i>
                  </a>
                </span>
              </div> */}
              <div className="inner ">
                <span className="icons">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089863869179&mibextid=ZbWKwL"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Facebook"
                  >
                    <i
                      className="fa fa-facebook-official"
                      aria-hidden="true"
                    ></i>
                  </a>
                  &emsp;
                  <a
                    href="https://api.whatsapp.com/send?phone=917305112340"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Whatsapp"
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a
                    href="https://www.linkedin.com/company/skill-zone-academy/"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Linkedin"
                  >
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a
                    href="mailto: info@skillzoneacademy.co.in"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Mail"
                  >
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a
                    href="tel:+917305112340"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Telephone"
                  >
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
