import React from "react";
import Marquee from "react-fast-marquee";

export default function SocialMediaDetails() {
  return (
    <div className="container-fluid bottom-bar-container">
      <div id="bottom-bar">
        <div id="bottom-bar-inner" className="d-none d-md-none d-sm-block d-lg-block">
          <div className="bottom-bar-inner-wrap ">
            <div className="bottom-bar-content">
              <div className="inner">
                <span className="address content">
                  <i className="fa fa-map-pin"></i>&nbsp; Anna nagar, Madurai -
                  625020
                </span>
                &emsp;| &emsp;
                <span className="phone content">
                  {" "}
                  <i className="fa fa-phone"></i>&nbsp; +91 73051-12340
                </span>{" "}
                &emsp;| &emsp;
                <span className="time content">
                  {" "}
                  <i className="fa fa-time"></i>&nbsp;7AM - 9PM ( Weekdays /
                  Weekend Batches Available )
                </span>
              </div>
            </div>
            <div className="bottom-bar-socials">
              <div className="inner">
                <span className="text">Follow us:&emsp;</span>
                <span className="icons">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089863869179&mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <i
                      className="fa fa-facebook-official"
                      aria-hidden="true"
                    ></i>
                  </a>
                  &emsp;
                  <a
                    href="https://api.whatsapp.com/send?phone=917305112340"
                    target="_blank"
                  >
                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a
                    href="https://www.linkedin.com/company/skill-zone-academy/"
                    target="_blank"
                  >
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a href="mailto: info@skillzoneacademy.co.in" target="_blank">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a href="tel:+917305112340">
                    <i className="fa fa-mobile" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className=" d-lg-none mb-1">
          <Marquee speed="40" gradient={false}>
            <div className="inner">
              <span className="address content">
                <i className="fa fa-map-pin"></i>&nbsp; Anna nagar, Madurai -
                625020
              </span>
              &emsp;| &emsp;
              <span className="phone content">
                <i className="fa fa-phone"></i>&nbsp; +91 73051-12340
              </span>
              &emsp;| &emsp;
              <span className="time content">
                <i className="fa fa-time"></i>&nbsp;7AM - 9PM ( Weekdays /
                Weekend Batches Available )
              </span>
              &emsp; &emsp;
              <span className="text">Follow us:&emsp;</span>
              <span className="icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100089863869179&mibextid=ZbWKwL"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-facebook-official" aria-hidden="true"></i>
                </a>
                &emsp;
                <a
                  href="https://api.whatsapp.com/send?phone=917305112340"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                </a>
                &emsp;
                <a
                  href="https://www.linkedin.com/company/skill-zone-academy/"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
                &emsp;
                <a
                  href="mailto: info@skillzoneacademy.co.in"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </a>
                &emsp;
                <a href="tel:+917305112340" className="text-white">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </a>
              </span>
            </div>
          </Marquee>
          {/* <marquee
            className="bottom-bar-inner-wrap d-md-none d-lg-none mb-1"
            behaviour="slide"
          >
            <div className="inner">
              <span className="address content">
                <i className="fa fa-map-pin"></i>&nbsp; Anna nagar, Madurai -
                625020
              </span>
              &emsp;| &emsp;
              <span className="phone content">
                <i className="fa fa-phone"></i>&nbsp; +91 73051-12340
              </span>
              &emsp;| &emsp;
              <span className="time content">
                <i className="fa fa-time"></i>&nbsp;7AM - 9PM ( Weekdays /
                Weekend Batches Available )
              </span>
              &emsp; &emsp;
              <span className="text">Follow us:&emsp;</span>
              <span className="icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100089863869179&mibextid=ZbWKwL"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-facebook-official" aria-hidden="true"></i>
                </a>
                &emsp;
                <a
                  href="https://api.whatsapp.com/send?phone=917305112340"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                </a>
                &emsp;
                <a
                  href="https://www.linkedin.com/company/skill-zone-academy/"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
                &emsp;
                <a
                  href="mailto: info@skillzoneacademy.co.in"
                  target="_blank"
                  className="text-white"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </a>
                &emsp;
                <a href="tel:+917305112340" className="text-white">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </a>
              </span>
            </div>
          </marquee> */}
        </div>
      </div>
    </div>
  );
}
