import React, { useEffect, useState } from "react";
import "../../style.css";
import Loader from "../Loader";
import NavHeader from "./../NavHeader";
import SocialMediaDetails from "./../SocialMediaDetails";

function Banner(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);
  return (
    <>
      <NavHeader />
      {loading ? (
        <Loader style={{ display: loading ? "none" : "block" }} />
      ) : (
        <img
          data-aos="fade-up"
          style={props?.styling}
          src={props?.src}
          className={props?.class}
          alt="..."
          loading="lazy"
        />
      )}

      <SocialMediaDetails />
    </>
  );
}

export default Banner;
