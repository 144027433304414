import React from "react";

function SideNavBar() {
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      element.scrollBottom += 100;
    }
  };
  return (
    <div
      className="col-12 col-md-12 col-lg-2 col-xl-2 my-5 courses-quick-link"
      data-aos="fade-right"
      data-aos-offset="400"
    >
      <div className="courses-quick-link-inner">
        <label className="course-sidebar-heading pb-2">Technologies</label>
        <ul className="nav flex-column">
          <li className="nav-item">
            <a
              className="nav-link active text-white"
              onClick={() => handleClickScroll("web")}
            >
              <i className="fa fa-laptop"></i>&nbsp; Web
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link text-white"
              onClick={() => handleClickScroll("cloud")}
            >
              <i className="fa fa-cloud-upload"></i>&nbsp; Cloud
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link text-white"
              onClick={() => handleClickScroll("analytics")}
            >
              <i className="fa fa-bar-chart" aria-hidden="true"></i>&nbsp;Data
              Analytics
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link text-white"
              onClick={() => handleClickScroll("big-data")}
            >
              <i className="fa fa-bar-chart" aria-hidden="true"></i>&nbsp;Big
              Data Frameworks
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link text-white"
              onClick={() => handleClickScroll("db")}
            >
              <i className="fa fa-database"></i>&nbsp; Database
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link link text-white"
              onClick={() => handleClickScroll("programming")}
            >
              <i className="fa fa-code" aria-hidden="true"></i>&nbsp;
              Programming
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link link text-white"
              onClick={() => handleClickScroll("devepos")}
            >
              <i className="fa fa-laptop" aria-hidden="true"></i>&nbsp; DevOps
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link link text-white"
              onClick={() => handleClickScroll("role-based")}
            >
              <i className="fa fa-th-large" aria-hidden="true"></i>&nbsp; Combo
              Courses
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideNavBar;
