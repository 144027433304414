import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";

export default function WeOffer() {
  const navigate = useNavigate();
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <div className="about section-padding my-5">
      <div className="container">
        <div className="row my-4" data-aos="zoom-in">
          <div className="col-12">
            <h5 className="home-content-main-title">
              Offering The Latest Software And IT Courses To Our Students!
            </h5>
            <p className="home-content-main-para">
              We offer a wide range of computer courses designed to equip
              learners with the skills and knowledge needed to succeed in
              today's technology-driven world. Our courses cover a variety of
              topics, including programming languages, web development, data
              analysis, and more.
            </p>
          </div>
        </div>
        <div className="container-fluid" id="we-offer">
          <OwlCarousel
            items={4}
            margin={25}
            autoplay={true}
            loop={true}
            navSpeed={true}
            autoplaySpeed={1000}
            autoplayTimeout={3000}
            autoplayHoverPause={true}
            responsive={state.responsive}
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Cloud Technologies</h5>
                  <p>
                    Cloud computing is the delivery of different services
                    through the Internet, including data storage, servers,
                    databases, networking, and software.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm we-offer-view-button"
                    onClick={() =>
                      navigate({
                        pathname: "/courses",
                        hash: "cloud",
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Web Technologies</h5>
                  <p>
                    Web designing is the process of planning, conceptualizing,
                    and implementing the plan for designing a website in a way
                    that is functional and offers a good user experience.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm we-offer-view-button"
                    onClick={() =>
                      navigate({
                        pathname: "/courses",
                        hash: "web",
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Programming Language</h5>
                  <p>
                    A programming language is a computer language programmers
                    use to develop software programs, scripts, or other sets of
                    instructions for computers to execute.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm we-offer-view-button"
                    onClick={() =>
                      navigate({
                        pathname: "/courses",
                        hash: "programming",
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Data Analytics</h5>
                  <p>
                    Data analytics converts raw data into actionable insights.
                    It includes a range of tools, technologies, and processes
                    used to find trends and solve problems by using data.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm we-offer-view-button"
                    onClick={() =>
                      navigate({
                        pathname: "/courses",
                        hash: "analytics",
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Big Data Frameworks</h5>
                  <p>
                    Big data frameworks are designed to process big data quickly
                    and efficiently, while also being secure. Big data
                    frameworks are usually open-source, meaning they're free
                    with the option of paying for support if you need it.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm we-offer-view-button"
                    onClick={() =>
                      navigate({
                        pathname: "/courses",
                        hash: "big-data",
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>DevOps</h5>
                  <p>
                    DevOps is the combination of cultural philosophies,
                    practices, and tools that increases an organization's
                    ability to deliver applications and services at high
                    velocity: evolving and improving products at a faster pace
                    than organizations using traditional software development
                    and infrastructure management processes.
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm we-offer-view-button"
                    onClick={() =>
                      navigate({
                        pathname: "/courses",
                        hash: "devepos",
                      })
                    }
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>

        {/* <div className="row" id="we-offer">
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3"
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="500"
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Cloud Technologies</h5>
                  <p>
                    Cloud computing is the delivery of different services
                    through the Internet, including data storage, servers,
                    databases, networking, and software.
                  </p>
                  <a href="#">View</a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3"
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="1000"
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Web Technologies</h5>
                  <p>
                    Web designing is the process of planning, conceptualizing,
                    and implementing the plan for designing a website in a way
                    that is functional and offers a good user experience.
                  </p>
                  <a href="#">View</a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3"
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="1500"
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Programming</h5>
                  <p>
                    A programming language is a computer language programmers
                    use to develop software programs, scripts, or other sets of
                    instructions for computers to execute.
                  </p>
                  <a href="#">View</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" id="we-offer">
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3"
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="2000"
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Analytics</h5>
                  <p>
                    Data analytics converts raw data into actionable insights.
                    It includes a range of tools, technologies, and processes
                    used to find trends and solve problems by using data.
                  </p>
                  <a href="#">View</a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3"
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="2000"
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>Big Data</h5>
                  <p>
                    Big data frameworks are designed to process big data quickly
                    and efficiently, while also being secure. Big data
                    frameworks are usually open-source, meaning they're free
                    with the option of paying for support if you need it.
                  </p>
                  <a href="#">View</a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 col-lg-4 col-xl-3"
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="2000"
          >
            <div className="card">
              <div className="box">
                <div className="content">
                  <h5>DevOps</h5>
                  <p>
                    DevOps is the combination of cultural philosophies,
                    practices, and tools that increases an organization's
                    ability to deliver applications and services at high
                    velocity: evolving and improving products at a faster pace
                    than organizations using traditional software development
                    and infrastructure management processes.
                  </p>
                  <a href="#">View</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
