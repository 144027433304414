import React, { useEffect } from "react";

import Skill_Zone_Academy_HTML_CSS from "../../assets/syllabus/Skill_Zone_Academy_HTML_CSS.pdf";
import Skill_Zone_Academy_C_Programming from "../../assets/syllabus/Skill_Zone_Academy_C_Programming.pdf";
import Skill_Zone_Academy_C_Plus from "../../assets/syllabus/Skill_Zone_Academy_C++Programming.pdf";
import Skill_Zone_Academy_PHP from "../../assets/syllabus/Skill_Zone_Academy_PHP.pdf";
import Skill_Zone_Academy_PowerBI from "../../assets/syllabus/Skill_Zone_Academy_PowerBI.pdf";
import Skill_Zone_Academy_Python from "../../assets/syllabus/Skill_Zone_Academy_Python.pdf";
import Skill_Zone_Academy_SAS from "../../assets/syllabus/Skill_Zone_Academy_SAS.pdf";
import Skill_Zone_Academy_SQL from "../../assets/syllabus/Skill_Zone_Academy_SQL.pdf";
import Skill_Zone_Academy_Tableau from "../../assets/syllabus/Skill_Zone_Academy_Tableau.pdf";
import Skill_Zone_Academy_Angular from "../../assets/syllabus/Skill_Zone_Academy_Angular.pdf";
import Skill_Zone_Academy_Javascript from "../../assets/syllabus/Skill_Zone_Academy_Javascript.pdf";
import SideNavBar from "../SideNavBar";
import { userDetailContext } from "../../App";
import { useLocation } from "react-router-dom";

const Card = (props) => {
  const { loggedIn, handleShow, setFromCourses, setPdfPath } =
    React.useContext(userDetailContext);

  const enquiryHandler = (courses, pdf) => {
    courses && setFromCourses(courses);
    setPdfPath(pdf);
    handleShow(true);
  };

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
      <div className="card m-1">
        <div className="card-head">
          <label className="card-title">{props.title}</label>
          <hr className="w-75 m-auto" />
        </div>

        <div className="m-2">
          <img
            className="card-img-top course-card-image"
            src={require(`../../assets/courses/${props.src}`)}
            alt={props.title}
          />
        </div>

        <div className="card-body">
          {/* <h5 className="card-title">{props.title}</h5> */}
          {/* <p className="card-text">{props.desc}</p> */}
        </div>
        <div className="card-footer">
          <p className="w-100 d-flex justify-content-between">
            <span className="badge bg-warning m-auto">
              <i className="fa fa-hourglass-start"></i>&nbsp;&nbsp;2 Months
            </span>
            &emsp;
            {props?.pdf && (
              <span className="badge bg-primary m-auto">
                {loggedIn ? (
                  <a
                    href={props?.pdf}
                    target="_blank"
                    className="download-syllabus"
                  >
                    <i className="fa fa-download">&nbsp;&nbsp;Syllabus</i>
                  </a>
                ) : (
                  <a
                    onClick={() => enquiryHandler(props?.title, props?.pdf)}
                    className="download-syllabus"
                  >
                    <i className="fa fa-download">&nbsp;&nbsp;Syllabus</i>
                  </a>
                )}

                {/* <a
                  // href={loggedIn ? props?.pdf : "#"}
                  target="_blank"
                  className="download-syllabus"
                >
                  <i className="fa fa-download">&nbsp;&nbsp;Syllabus</i>
                </a> */}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

function CousesContent(props) {
  const loggedIn = localStorage.getItem("loggedIn");

  // const courses = [
  //   {
  //     id: 1,
  //     section: "web",
  //     title: "HTML",
  //     src: "HTML.png",
  //     pdf: { Skill_Zone_Academy_HTML_CSS },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 2,
  //     title: "CSS",
  //     section: "web",
  //     src: "CSS.png",
  //     pdf: { Skill_Zone_Academy_HTML_CSS },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 3,
  //     title: "JavaScript",
  //     src: "Java Script.png",
  //     section: "web",
  //     pdf: { Skill_Zone_Academy_Javascript },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 4,
  //     title: "React Js",
  //     section: "web",
  //     src: "React.png",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 5,
  //     title: "Angular",
  //     section: "web",
  //     src: "angular-4.jpg",
  //     pdf: { Skill_Zone_Academy_Angular },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 6,
  //     title: "Azure",
  //     section: "cloud",
  //     src: "Azure.png",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 7,
  //     title: "AWS",
  //     section: "cloud",
  //     src: "AWS_1.png",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 8,
  //     title: "Python",
  //     section: "analytics",
  //     src: "Python.png",
  //     pdf: { Skill_Zone_Academy_Python },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 9,
  //     title: "SAS",
  //     section: "analytics",
  //     src: "SAS.png",
  //     pdf: { Skill_Zone_Academy_SAS },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 10,
  //     title: "Tableau",
  //     section: "analytics",
  //     src: "Tableau.png",
  //     pdf: { Skill_Zone_Academy_Tableau },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 11,
  //     title: "Power BI",
  //     section: "analytics",
  //     src: "Power BI.jpg",
  //     pdf: { Skill_Zone_Academy_PowerBI },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 11,
  //     title: "MS SQL Server",
  //     section: "db",
  //     src: "sql_1.png",
  //     pdf: { Skill_Zone_Academy_SQL },
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 11,
  //     title: "Mysql",
  //     section: "analytics",
  //     src: "mysql.jpg",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 11,
  //     title: "Oracle",
  //     section: "analytics",
  //     src: "oracle.jpg",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 11,
  //     title: "Teradata",
  //     section: "analytics",
  //     src: "teradata.png",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  //   {
  //     id: 11,
  //     title: "Postgresql",
  //     section: "analytics",
  //     src: "postgresql.png",
  //     pdf: "",
  //     desc: " Some quick example text to build on the card title.",
  //   },
  // ];

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <div className="container-fluid gx-5" id="course-container">
        <div className="row">
          <SideNavBar />
          <div className="col-12 col-md-12 col-lg-10 col-xl-10">
            <div
              className="web-technologies"
              id="web"
              data-aos="fade-up"
              // data-aos-offset="400"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">Web Technologies</h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4">
                <div className="row">
                  <Card
                    title="HTML"
                    src="HTML.png"
                    pdf={Skill_Zone_Academy_HTML_CSS}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="CSS"
                    src="CSS.png"
                    pdf={Skill_Zone_Academy_HTML_CSS}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="JavaScript"
                    src="Java Script.png"
                    pdf={Skill_Zone_Academy_Javascript}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="React Js"
                    src="React.png"
                    pdf={Skill_Zone_Academy_HTML_CSS}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Angular"
                    src="angular-4.jpg"
                    pdf={Skill_Zone_Academy_Angular}
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="cloud-technologies mt-4"
              id="cloud"
              data-aos="fade-up"
              // data-aos-offset="400"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">Cloud Technologies</h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4">
                <div className="row">
                  <Card
                    title="Azure"
                    src="Azure.png"
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="AWS"
                    src="AWS_1.png"
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="analytics mt-4"
              id="analytics"
              data-aos="fade-up"
              // data-aos-offset="300"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">
                    Data Engineering & Data Analytics
                  </h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4" data-aos="fade-up">
                <div className="row">
                  <Card
                    title="Python"
                    src="Python.png"
                    pdf={Skill_Zone_Academy_Python}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="SAS"
                    src="SAS.png"
                    pdf={Skill_Zone_Academy_SAS}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Tableau"
                    src="Tableau.png"
                    pdf={Skill_Zone_Academy_Tableau}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Power BI"
                    src="Power BI.jpg"
                    pdf={Skill_Zone_Academy_PowerBI}
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="big-data mt-4"
              id="big-data"
              data-aos="fade-up"
              // data-aos-offset="400"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">Big Data Frameworks</h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4" data-aos="fade-up">
                <div className="row">
                  <Card
                    title="Hadoop"
                    src="hadoop.png"
                    pdf={""}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="PySpark"
                    src="Pyspark.png"
                    pdf={""}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Hive"
                    src="Hive.png"
                    pdf={""}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Kafka"
                    src="kafka.png"
                    pdf={""}
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="database mt-4"
              id="db"
              data-aos="fade-up"
              // data-aos-offset="400"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">Database</h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4">
                <div className="row">
                  <Card
                    title="MS SQL Server"
                    src="sql_1.png"
                    pdf={Skill_Zone_Academy_SQL}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Oracle"
                    src="oracle.jpg"
                    pdf={Skill_Zone_Academy_SQL}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Mysql"
                    src="mysql.jpg"
                    pdf={Skill_Zone_Academy_SQL}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Teradata"
                    src="teradata.png"
                    pdf={Skill_Zone_Academy_SQL}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Postgresql"
                    src="postgresql.png"
                    pdf={Skill_Zone_Academy_SQL}
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="programming mt-4"
              id="programming"
              data-aos="fade-up"
              // data-aos-offset="400"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">Programming</h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4">
                <div className="row">
                  <Card
                    title="C Programming"
                    src="C Programming.jpg"
                    pdf={Skill_Zone_Academy_C_Programming}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="C++ Programming"
                    src="C++.png"
                    pdf={Skill_Zone_Academy_C_Plus}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="PHP"
                    src="PHP.jpg"
                    pdf={Skill_Zone_Academy_PHP}
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Laravel"
                    src="laravel.png"
                    pdf={Skill_Zone_Academy_C_Plus}
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="programming mt-4"
              id="devepos"
              data-aos="fade-up"
              // data-aos-offset="400"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">DevOps </h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4">
                <div className="row">
                  <Card
                    title="DevOps"
                    src="devepos.png"
                    pdf={""}
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
            <hr className="section-break" />
            <div
              className="role-based mt-4"
              id="role-based"
              data-aos="fade-up"
              // data-aos-offset="200"
            >
              <div className="row my-2">
                <div className="col-12">
                  <h6 className="course-heading">Combo Courses</h6>
                </div>
              </div>
              <div id="courses-section" className="mb-4">
                <div className="row">
                  <Card
                    title="UI Developer"
                    src="frontend4.jpg"
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Backend Developer"
                    src="backend1.jpg"
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="Full Stack Developer"
                    src="fullstack-1.jpg"
                    desc=" Some quick example text to build on the card title."
                  />
                  <Card
                    title="BI Developer"
                    src="bi-developer1.png"
                    desc=" Some quick example text to build on the card title."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CousesContent;
