import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { APP_URL, userDetailContext } from "../App";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function MyVerticallyCenteredModal(props) {
  const { setLoggedIn, fromCourses, pdfPath } =
    React.useContext(userDetailContext);
  const [mobile, setMobile] = useState("");
  const [Emobile, setEMobile] = useState("");
  const [ECourses, setECourses] = useState("");
  const [course, setCourse] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    event.preventDefault();
    setValidated(true);
    submitEnquiry();
  };

  const successToaster = (message) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const warningToaster = (message) =>
    toast.warn(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const resetForm = () => {
    setECourses("");
    setEMobile("");
    setValidated(false);
  };
  const submitEnquiry = () => {
    if (
      mobile.length == 0 ||
      (mobile.length > 0 && mobile.length < 10) ||
      (course === "" && fromCourses === "")
    ) {
      mobile.length === 0 && setEMobile("Mobile No. is required");
      mobile.length > 0 &&
        mobile.length < 10 &&
        setEMobile("Mobile No. should be in 10 digits");
      course === "" && setECourses("Course is required");
    } else {
      const payload = {
        mobile_number: mobile,
        courses: fromCourses || course,
      };
      axios
        .post(`${APP_URL}/enquiry`, payload)
        .then((response) => {
          setLoggedIn(true);
          successToaster(response?.data?.message);
          localStorage.setItem("loggedIn", true);
          props?.handleClose();
          resetForm();
          fromCourses && pdfPath && downloadMyFile(pdfPath);
        })
        .catch(function (error) {
          warningToaster(error?.response?.data?.message);
          resetForm();
        });
    }
  };
  const downloadMyFile = (path) => {
    console.log(path);
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    link.setAttribute("href", path);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <>
      <Modal show={props?.show} onHide={props?.handleClose} id="enquiry-modal">
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="p-2" closeButton>
            <Modal.Title style={{ fontSize: "22px" }}>Enquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="validationCustom03">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mobile Number"
                  required
                  maxLength={10}
                  minLength={10}
                  autoFocus
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setMobile(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {Emobile}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              {!fromCourses && (
                <Form.Group
                  className="mb-1"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Courses Looking For</Form.Label>
                  <Form.Select
                    size="sm"
                    required
                    aria-label="Default select example"
                    onChange={(e) => setCourse(e.target.value)}
                  >
                    <option value="">--Select--</option>
                    <optgroup label="Web">
                      <option value="HTML">HTML</option>
                      <option value="CSS">CSS</option>
                      <option value="JavaScript">JavaScript</option>
                      <option value="ReactJs">ReactJs</option>
                      <option value="Angular">Angular</option>
                    </optgroup>
                    <optgroup label="Cloud">
                      <option>Azure</option>
                      <option>AWS </option>
                    </optgroup>
                    <optgroup label="Analytics">
                      <option value="Python">Python</option>
                      <option value="SAS">SAS</option>
                      <option value="Tableau">Tableau</option>
                      <option value="Power BI">Power BI</option>
                    </optgroup>
                    <optgroup label="Database">
                      <option value="MS SQL Server">MS SQL Server</option>
                      <option value="Oracle">Oracle</option>
                      <option value="Mysql">Mysql</option>
                      <option value="Teradata">Teradata</option>
                      <option value="Postgresql">Postgresql</option>
                    </optgroup>

                    <optgroup label="Programming">
                      <option value="C Programming">C Programming</option>
                      <option value="C++ Programming">C++ Programming</option>
                      <option value="PHP">PHP</option>
                      <option value="Laravel">Laravel</option>
                      <option value="Postgresql">Postgresql</option>
                    </optgroup>
                    <optgroup label="Package">
                      <option value="UI Developer">UI Developer</option>
                      <option value="Backend Developer">
                        Backend Developer
                      </option>
                      <option value="Full Stack Developer">
                        Full Stack Developer
                      </option>
                      <option value="BI Developer">BI Developer</option>
                    </optgroup>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {ECourses}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-1">
            {/* <Button
            variant="primary"
            className="font-size-6 mx-auto"
            onClick={submitEnquiry}
          >
            Submit
          </Button> */}
            <Button type="submit" className="font-size-6 mx-auto">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default MyVerticallyCenteredModal;
