import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../Utilities/Footer";
import Banner from "../../Utilities/courses/Banner";
import ContactContent from "../../Utilities/contact/ContactContent";

function ContactUs() {
  const style = { objectFit: "cover", maxHeight: "13em" };
  return (
    <>
      <Banner
        src={require("../../assets/contact/contact-us-1.jpg")}
        class="d-block w-100 h-50 section"
        styling={style}
      />
      <br />
      <ContactContent />
      <hr className="section-break" />

      <Footer />
    </>
  );
}

export default ContactUs;
