import React from "react";

export default function Features() {
  return (
    <div className="feature section-padding my-5">
      <div className="container">
        <div className="row my-4">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="img-mons">
              <div className="row">
                <div className="col-12 ">
                  <h6 className="sub-title">Feature</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 className="home-content-main-title">
                    Real-World Knowledge and Personalized Guidance
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="home-about-desc">
                    We are mainly suggesting the students to go through the
                    following four processes in the list of Latest of
                    Technologies that will bring the success in entering IT
                    career as planned.
                  </p>
                </div>
              </div>
              <div className="row about-features d-flex justify-content-center">
                <div className="col-9 w-75" data-aos="fade-right">
                  <ul className="feature-bullet-in">
                    <li>
                      <small className="text-muted">Professional Training</small>
                    </li>
                    <li>
                      <small className="text-muted">
                        Identify the Strength
                      </small>
                    </li>
                    <li>
                      <small className="text-muted">Invest Yourself </small>
                    </li>
                    <li>
                      <small className="text-muted">Master the skill set</small>
                    </li>
                    <li>
                      <small className="text-muted">
                        Crack your job in Desired Area
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-left">
            <div className="img1 wow imago animated ">
              <img
                className="w-75 feature-image"
                style={{ borderRadius: "20px" }}
                src={require("../../assets/banner-4.jpeg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
