import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../Utilities/Footer";
import Banner from "../../Utilities/courses/Banner";
import CousesContent from "../../Utilities/courses/CousesContent";
import Loader from "../../Utilities/Loader";

function Courses() {
  sessionStorage.setItem("courseLoader", true);
  const style = { objectFit: "cover", maxHeight: "13em" };
  const hasLoaderBeenShown = sessionStorage.getItem("loaderShown");

  return (
    <>
      <Banner
        src={require("../../assets/courses-3.jpg")}
        class="d-block w-100 h-50 section"
        styling={style}
      />

      <br />
      <CousesContent />
      <hr className="section-break" />

      <Footer />
    </>
  );
}

export default Courses;
