import React, { useContext, useEffect, useState } from "react";

import Carousel from "../../Utilities/Carousel";
import HomeAbout from "../../Utilities/HomeAbout";
import WeOffer from "../../Utilities/home/WeOffer";
import Footer from "../../Utilities/Footer";
import Features from "../../Utilities/home/Features";
import Vision from "../../Utilities/home/Vision";
import { userDetailContext } from "../../App";
import Loader from "../../Utilities/Loader";

function Home() {
  const { loader, setLoader } = useContext(userDetailContext);
  const hasLoaderBeenShown = sessionStorage.getItem("loaderShown");

  useEffect(() => {
    if (!hasLoaderBeenShown) {
      setTimeout(() => {
        sessionStorage.setItem("loaderShown", true);
        setLoader(false);
      }, 1000);
    }
  }, []);

  return !hasLoaderBeenShown ? (
    <Loader />
  ) : (
    <>
      <Carousel />
      <br />
      <HomeAbout />
      <hr className="section-break" />
      <WeOffer />
      <hr className="section-break" />
      <Features />
      <hr className="section-break" />
      <Vision />
      <Footer />
    </>
  );
}

export default Home;
