import React from "react";
import "../style.css";
import NavHeader from "./NavHeader";
import SocialMediaDetails from "./SocialMediaDetails";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Carousel() {
  return (
    <>
      <NavHeader />
      {/* <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active overlay">
            <img
              src={require("../assets/banner-16.jpg")}
              className="d-block w-100 intro-section  section"
              alt="..."
              loading="eager"
            />
          </div>
          <div className="carousel-item overlay">
            <img
              src={require("../assets/main-1.jpg")}
              className="d-block w-100  intro-section  section"
              alt="..."
            />
          </div>
          <div className="carousel-item overlay">
            <img
              src={require("../assets/main-3.jpg")}
              className="d-block w-100  intro-section  section"
              alt="..."
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}
      <OwlCarousel
        items={1}
        autoplay={true}
        loop={true}
        navSpeed={true}
        lazyLoad={true}
        dots={true}
        dotsEach={true}
      >
        <div className="item overlay">
          <img
            src={require("../assets/banner-16.jpg")}
            className="d-block w-100 intro-section  section h-auto"
            alt="..."
            loading="eager"
          />
        </div>
        <div className="item ">
          <img
            src={require("../assets/main-1.jpg")}
            className="d-block w-100  intro-section  section h-auto"
            alt="..."
          />
        </div>
        <div className="item ">
          <img
            src={require("../assets/main-3.jpg")}
            className="d-block w-100  intro-section  section h-auto"
            alt="..."
          />
        </div>
      </OwlCarousel>
      <SocialMediaDetails />
    </>
  );
}

export default Carousel;
