import React from "react";
import { Dna } from "react-loader-spinner";

function Loader() {
  return (
    <div className="loader-container">
      <div className="backdrop"></div>
      <Dna
        visible={true}
        height="100"
        width="180"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    </div>
  );
}

export default Loader;
