import React from "react";

function ContactContent(props) {
  return (
    <>
      <div className="container" id="course-container">
        <div className="row mb-5 mt-3">
          <div
            className="col-12 col-md-12 col-lg-6  col-xl-6"
            data-aos="fade-right"
          >
            <h6>Contact Us</h6>
            <p className="home-about-desc">
              Welcome to our Skill Zone Academy! We appreciate your interest in
              our programs and would be happy to answer any questions you may
              have. If you would like to get in touch with us, you can do so
              through the following methods.
            </p>
            <p className="home-about-desc">
              Our team is available to answer your queries and provide you with
              the information you need to make an informed decision about our
              courses. Whether you have a question about our curriculum, class
              schedules, pricing, or anything else, we're here to help.
            </p>
            <p className="home-about-desc">
              Thank you for considering our Computer Courses Learning website.
              We look forward to hearing from you and helping you achieve your
              goals!
            </p>
            <h6>Official Address</h6>
            <p className="home-about-desc">
              Plot No. 391, Dhanalakshmi Complex,
              <br /> Anna nagar main road, <br /> Opposite to Indian Bank,
              <br /> Madurai-625020. <br />
              <br />
              <a href="tel:+917305112340">
                <i
                  className="fa fa-mobile"
                  aria-hidden="true"
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  &nbsp;+91 73051-12340
                </i>
              </a>
              <br />
              <a href="mailto: info@skillzoneacademy.co.in" target="_blank">
                <i
                  className="fa fa-envelope"
                  aria-hidden="true"
                  style={{ fontSize: "18px", fontWeight: 500 }}
                >
                  &nbsp; info@skillzoneacademy.co.in
                </i>
              </a>
            </p>
            <p></p>
          </div>
          <div
            className="col-12 col-md-12 col-lg-6  col-xl-6"
            data-aos="fade-left"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15720.599729816005!2d78.1466063!3d9.9214685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c5fe019c4045%3A0xca588fef099e23e7!2sSkill%20Zone%20Academy!5e0!3m2!1sen!2sin!4v1678689176124!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="contact-map h-100 w-100"
            ></iframe>
          </div>
        </div>
        <hr className="section-break" />
        <div className="row">
          <div className="col-4"></div>
        </div>
        <hr className="section-break" />

        <div className="row my-4">
          <div className="col">
            <div className="bottom-bar-socials">
              <div className="inner">
                <span className="contact-us-icon">
                  <a
                    href="https://www.facebook.com/profile.php?id=100089863869179&mibextid=ZbWKwL"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Facebook"
                  >
                    <i
                      className="fa fa-facebook-official fa-2x"
                      aria-hidden="true"
                    ></i>
                  </a>
                  &emsp;
                  <a
                    href="https://api.whatsapp.com/send?phone=917305112340"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Whatsapp"
                  >
                    <i className="fa fa-whatsapp fa-2x" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a
                    href="https://www.linkedin.com/company/skill-zone-academy/"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Linkedin"
                  >
                    <i
                      className="fa fa-linkedin-square fa-2x"
                      aria-hidden="true"
                    ></i>
                  </a>
                  &emsp;
                  <a
                    href="mailto: info@skillzoneacademy.co.in"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Mail"
                  >
                    <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                  </a>
                  &emsp;
                  <a
                    href="tel:+917305112340"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Telephone"
                  >
                    <i className="fa fa-mobile fa-2x" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactContent;
