import "./App.css";
import Home from "./components/Home/Home";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Courses from "./components/Courses/Courses";
import { ToastContainer } from "react-toastify";
import Loader from "./Utilities/Loader";
import ContactUs from "./components/Contact/ContactUs";
import Business from "./components/Business/Business";

// export const APP_URL = "http://127.0.0.1:8000/api";
export const APP_URL = "http://api.skillzoneacademy.co.in/api";

const NavScrollTop = ({ children }) => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export const userDetailContext = React.createContext(null);

function App() {
  const loaderFirstTime = sessionStorage.getItem("modalSkip");

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);
  const [loader, setLoader] = useState(true);
  const [firstTimeSkip, setFirstTimeSkip] = useState(false);
  const [fromCourses, setFromCourses] = useState("");
  const [pdfPath, setPdfPath] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem("modalSkip", true);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log(loggedIn);
  }, [loggedIn]);
  return (
    <Router>
      <NavScrollTop>
        <ToastContainer />
        <userDetailContext.Provider
          value={{
            loggedIn,
            setLoggedIn,
            show,
            setShow,
            handleClose,
            handleShow,
            loader,
            setLoader,
            firstTimeSkip,
            setFirstTimeSkip,
            fromCourses,
            setFromCourses,
            pdfPath,
            setPdfPath,
          }}
        >
          <div className="App">
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/courses" element={<Courses />}></Route>
              <Route exact path="/business" element={<Business />}></Route>
              <Route exact path="/contact-us" element={<ContactUs />}></Route>
            </Routes>
          </div>
        </userDetailContext.Provider>
      </NavScrollTop>
    </Router>
  );
}

export default App;
