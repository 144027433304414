import React from "react";

export default function HomeAbout() {
  return (
    <div className="about section-padding my-5" id="home-about">
      <div className="container">
        <div className="row my-4">
          <div className="col-lg-6 d-flex">
            <div className="img-mons">
              <div className="row">
                <div className="col-md-5 cmd-padding d-flex align-items-center">
                  <div
                    className="img1 wow imago animated"
                    data-aos="fade-right"
                  >
                    <img
                      className="w-100 home-abt-image"
                      src={require("../assets/banner-4.jpeg")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-7 cmd-padding my-2">
                  <div className="img2 wow imago animated" data-aos="fade-down">
                    <img
                      className="w-100 home-abt-image"
                      src={require("../assets/banner-8.jpeg")}
                      alt=""
                    />
                  </div>
                  <div
                    className="img3 wow imago animated my-2"
                    data-aos="fade-up"
                  >
                    <img
                      className="w-100 home-abt-image"
                      src={require("../assets/mini-card-1.jpg")}
                      alt=""
                      
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="img-mons">
              <div className="row">
                <div className="col-12 ">
                  <h6 className="sub-title">About Us</h6>
                </div>
              </div>
              <div className="row" data-aos="zoom-in-up">
                <div className="col-12">
                  <h6
                    className="home-content-main-title"
                    style={{ fontSize: "22px" }}
                  >
                    Empowering Your Future Through Latest IT Technologies!
                  </h6>
                </div>
              </div>
              <div className="row" data-aos="zoom-in-up">
                <div className="col-12">
                  <p className="home-about-desc">
                    Skill Zone is mainly offering Courses pertaining to Latest
                    IT Technologies by the working Professionals with reasonable
                    amount of work experience in respective skill sets, also
                    offering Spoken English Course which will help the Students,
                    Working Professionals, Entrepreneurs those who want to
                    improve their Oral Communication skills and their Written
                    ability in English as well.
                  </p>
                </div>
              </div>
              <div
                className="row about-features d-flex justify-content-center"
                data-aos="zoom-out-up"
              >
                <div className="col-9 w-75">
                  <div className="row" id="about-us-feature">
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 my-2">
                      <div className="card gradient-box h-100">
                        <div className="card-body">
                          <img src={require("../assets/goal.png")} width="40" />
                          <p className=" card-text-feature">
                            Career Development
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 my-2 ">
                      <div className="card gradient-box h-100">
                        <div className="card-body">
                          <img
                            src={require("../assets/award.png")}
                            width="40"
                          />
                          <p className=" card-text-feature">
                          Personalized Learning
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 my-2">
                      <div className="card gradient-box h-100">
                        <div className="card-body">
                          <img
                            src={require("../assets/professionals.png")}
                            width="40"
                          />
                          <p className=" card-text-feature">
                            Professional Training
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
