import React, { useEffect, useState } from "react";
import { userDetailContext } from "../../App";
import parse from "html-react-parser";

const JobCard = (props) => {
  const { loggedIn, handleShow } = React.useContext(userDetailContext);
  const [showReadMore, setShowReadMore] = useState(false);
  return (
    <div className="row">
      <div className="col-12 my-2">
        <div className="card m-1 card-shadow">
          {/* <div className="card-body d-flex flex-direction-row"> */}
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-2 col-lg-2 col-xl-2 place-center">
                <img
                  className="card-img-top course-card-image"
                  src={props?.data?.image}
                  alt={props?.data?.title}
                  style={{ maxWidth: "150px", maxHeight: "150px" }}
                />
              </div>
              <div className="col-12 col-md-10 col-lg-10 col-xl-10">
                <div className="job-details">
                  <a
                    href={props?.data?.link}
                    target="_blank"
                    className="d-none d-sm-block"
                  >
                    <button className="btn btn-warning text-white btn-sm details-btn">
                      Apply
                    </button>
                  </a>
                  <h6 className="job-details-header">{props?.data?.title}</h6>
                  <p className="job-role-text">
                    Role:&nbsp;<b>{props?.data?.role}</b>
                  </p>
                  <p className="job-exp-text">
                    Experience:&nbsp;<b>{props?.data?.experience}</b>
                  </p>
                  <p className="job-exp-text">
                    Location:&nbsp;<b>{props?.data?.location}</b>
                  </p>
                  <span className="text-muted" style={{ fontSize: "15px" }}>
                    Description
                  </span>
                  <div style={{ fontSize: "15px" }}>
                    {/* {props?.data?.desc?.length <= 200
                      ? parse(props?.data?.desc)
                      : parse(`${props?.data?.desc?.substring(0, 200)} .....`)} */}
                    {parse(props?.data?.desc)}
                  </div>
                  <div className="d-flex justify-content-center">
                    <a
                      href={props?.data?.link}
                      target="_blank"
                      className="d-md-none d-lg-none"
                    >
                      <button className="btn btn-warning text-white btn-sm details-btn-mobile">
                        Apply
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function BusinessContent(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("./jobs.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log("data", data);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      <div className="container gx-5" id="course-container">
        {data?.map((item) => (
          <JobCard data={item} />
        ))}
      </div>
    </>
  );
}

export default BusinessContent;
