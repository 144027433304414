import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../Utilities/Footer";
import Banner from "../../Utilities/courses/Banner";
import BusinessContent from "../../Utilities/business/BusinessContent";

function Business() {
  const style = { objectFit: "cover", maxHeight: "13em" };
  const [loading, setLoading] = useState(false);

  const hideLoader = () => {
    // alert();
    console.log(loading);
    setLoading(false);
  };
  return (
    <>
      <Banner
        src={require("../../assets/business/jobs-3.webp")}
        class="d-block w-100 h-50 section"
        styling={style}
        hideLoader={hideLoader}
      />
      <br />
      <BusinessContent />
      <hr className="section-break" />

      <Footer />
    </>
  );
}

export default Business;
